import React from 'react';
import i18n from 'i18next';
import { Authenticator } from '@aws-amplify/ui-react';
import { useTranslation, initReactI18next } from 'react-i18next';
// eslint-disable-next-line
import '@aws-amplify/ui-react/styles.css';
import { TailwindExample } from './components/TailwindExample/TailwindExample';
import rsTranslation from './assets/translations/rs.json';
import enTranslation from './assets/translations/en.json';
import rsIcon from './assets/img/serbia_640.png';
import ukIcon from './assets/img/uk_640.png';
import './App.css';

const formFields = {
  signUp: {
    email: {
      order: 1,
    },
    family_name: {
      order: 2,
    },
    given_name: {
      order: 3,
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      rs: {
        translation: rsTranslation,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  const { t } = useTranslation();

  const changeLanguage = (lan) => {
    i18n.changeLanguage(lan);
  };

  return (
    <Authenticator formFields={formFields}>
      {({ signOut, user }) => (
        <div>
          <img src={rsIcon} alt="serbian_flag" width="48px" onClick={() => changeLanguage('rs')} />
          <img src={ukIcon} alt="uk_flag" width="48px" onClick={() => changeLanguage('en')} />
          <p>
            {t('welcomeMessage')}
            :
            {user.username}
          </p>
          <button type="button" onClick={signOut}>
            {t('signOut')}
          </button>
          <TailwindExample user={user} />
        </div>
      )}
    </Authenticator>
  );
}

export default App;
